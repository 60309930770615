import axios from "axios";
import moment from "moment";
import queryString from "query-string";

export async function getInstitutions(institution_name) {
  let url = `${process.env.REACT_APP_BIDI_API_V2_URL}/v2/institutions/login_methods`;
  if (institution_name) {
    url = url + `?name=${institution_name}`;
  }
  return axios.get(url);
}

export async function getInstitution(institution_name) {
  const response = await axios.get(`${process.env.REACT_APP_BIDI_API_V2_URL}/v2/institutions/find-by-name/${institution_name}`);
  return response.data;
}

export async function getRegisterExtraFields(institution_name) {
  return axios.get(`${process.env.REACT_APP_BIDI_API_V2_URL}/v2/institutions/${institution_name}/register_extra_fields`);
};

export async function register(institution_name, { external_id, email, name, lastname, password, membership_number }, extrafieldsValues) {
  const form = new FormData();
  form.append('university_name', institution_name);
  form.append('external_id', external_id);
  form.append('email', email);
  form.append('name', name);
  form.append('lastname', lastname);
  form.append('password', password);
  form.append('password_repetida', password);
  if (membership_number) {
    form.append('membership_number', membership_number);
  }

  Object.entries(extrafieldsValues).forEach(([key, value]) => {
    form.append(key, moment.isMoment(value) ? value.format("DD/MM/yyyy") : value); //TODO remove ternary operator
  });

  return axios.post(`${process.env.REACT_APP_BIDI_API_URL}/v2/user`, form);
};

export async function confirmAccount(token) {
  return axios.get(`${process.env.REACT_APP_BIDI_API_URL}/account/confirm/${token}`);
}

export async function forgetPassword(email, institution_name) {
  const form = new FormData();
  form.append('email', email);
  form.append('institution_name', institution_name);


  return axios.post(`${process.env.REACT_APP_BIDI_API_URL}/v2/user/forget`, form);
}

export async function changePassword(token, password) {
  return axios.put(`${process.env.REACT_APP_BIDI_API_URL}/v2/login/password?token=${token}&password=${password}`);
}

export async function changePasswordMobileApp(token, password) {
  return axios.post(`${process.env.REACT_APP_BIDI_API_URL}/v2/customer/change_password`, { password, token });
}

export async function updateOrRegisterCustomer(bidi_access_token, email, password) {
  return axios.post(`${process.env.REACT_APP_BIDI_API_URL}/v2/customer/validate`, { email, password }, { headers: { "x-access-token": bidi_access_token } }).then(response => {
    let apiUrl = "/v2/customer/register";

    if (response.data.message === "email_found") {
      apiUrl = "/v2/customer/update";
    }

    return axios.post(`${process.env.REACT_APP_BIDI_API_URL}${apiUrl}`, { email, password }, { headers: { "x-access-token": bidi_access_token } });
  });
}

export async function login(username, password, institution_name, login_method) {
  const form = new FormData();
  form.append('external_id', username);
  form.append('password', password);
  form.append('university_name', institution_name);

  if (login_method === 'native') {
    return axios.post(`${process.env.REACT_APP_BIDI_API_URL}/v2/session`, form);
  }

  return axios.post(`${process.env.REACT_APP_BIDI_API_URL}/external/login`, form);
}

export async function loginByToken(token) {
  return axios.get(`${process.env.REACT_APP_BIDI_API_URL}/login?token=${token}`);
}

export async function getTags(institution_name, limit) {
  return axios.get(`${process.env.REACT_APP_BIDI_API_V2_URL}/v1/collections?client=${institution_name}&type=tag&limit=${limit}`).then(response => response.data.collections);
}

export async function getContributorBiography(institution_name, id) {
  return axios.get(`${process.env.REACT_APP_EBOOKDELIVERY_API_URL}/v1.5/contributors/${id}?client=${institution_name}`);
}
export async function getBooks(institution_name, offset, limit, query, addFilterInfo, sort, dir, filters, catalogs, has_custom_genres) {
  let url = `${process.env.REACT_APP_EBOOKDELIVERY_API_URL}/v1.5/books?client=${institution_name}&lambda_catalog_copies=true&limit=${limit}&offset=${offset}&size=130,172`;

  if (has_custom_genres) {
    url = `${process.env.REACT_APP_EBOOKDELIVERY_API_URL}/v1.5/bookscustomtags?client=${institution_name}&lambda_catalog_copies=true&limit=${limit}&offset=${offset}&size=130,172`;
  }

  if (query) {
    url = url + `&query=${query}`
  }
  if (addFilterInfo) {
    url = url + '&addfilterinfo=contributors,categories,publishers,tags,custom_genres'
  }
  if (sort && dir) {
    url = url + `&sort=${sort}:${dir}`;
  }

  url = url + `&filter=published:1`;
  if (filters) {
    filters.forEach(filter => {
      if (filter.value.length === 0) return;
      url = url + `;${filter.name}:${filter.value.join(',')}`;
    });
  }

  if (catalogs && catalogs.length > 0) {
    url = url + ';catalogId:' + catalogs;
  }

  return axios.get(url);
}

export async function getBook(institution_name, id) {
  return await axios.get(`${process.env.REACT_APP_EBOOKDELIVERY_API_URL}/v1.5/books/${id}?client=${institution_name}&lambda_catalog_copies=true&size=204,270`)
    .then(response => response.data);
}
/*
export async function getBookReader(institution_name, id) {
  return await axios.get(`${process.env.REACT_APP_EBOOKDELIVERY_API_URL}/v1.5/books/${id}?client=${institution_name}&lambda_catalog_copies=true&size=204,270`)
      .then(response => response.data);
}
*/
export async function getBookSuggestions(institution_name, id) {
  return await axios.get(`${process.env.REACT_APP_BIDI_API_V2_URL}/v2/books/${id}/suggestions?client=${institution_name}`)
    .then(response => response.data);
}


export async function getDownloads(customer_access_token, institution_name, user_id, stock_release = 0) {
  return axios.get(`${process.env.REACT_APP_BIDI_API_URL}/v2/downloads?client_name=${institution_name}&frontuser_id=${user_id}6&stock_release=${stock_release}`, { headers: { "x-access-token": customer_access_token } }).then(response => {
    const downloads = response.data.downloads.filter(d => d.book).map(download => {
      return { ...download, downloadUrl: download.downloadUrl.replace("http://", "https://").replace("licencias.bajalibros.com", "licencias.conectarigualdad.edu.ar") }
    });
    return { data: { count: downloads.length, downloads: downloads } }
  });
}

export async function createDownload(customer_access_token, institution_name, user_id, catalog_item_id, isLoan) {
  const type = (isLoan) ? "loan" : "buy"; //loan or download (buy)

  const data = {
    'client_name': institution_name,
    'frontuser_id': user_id,
    'catalog_item_id': catalog_item_id,
    'type': type
  };

  return await axios.post(`${process.env.REACT_APP_BIDI_API_URL}/v2/downloads`, data, { headers: { "x-access-token": customer_access_token } }).then(response => {
    if (response.data.downloads.length > 0) {
      const download = response.data.downloads[0];
      download.downloadUrl = download.downloadUrl.replace("http://", "https://").replace("licencias.bajalibros.com", "licencias.conectarigualdad.edu.ar");
      return download;
    }
  });
};

export async function deleteDownload(customer_access_token, delivery_id) {
  return axios.delete(`${process.env.REACT_APP_BIDI_API_URL}/v2/ebookHistory?deliveryId=${delivery_id}`, { headers: { "x-access-token": customer_access_token } });
}

export async function returnDownload(customer_access_token, delivery_id, feedback_id) {
  return axios.delete(`${process.env.REACT_APP_BIDI_API_URL}/v2/downloads/${delivery_id}`, { data: { feedback: feedback_id }, headers: { "x-access-token": customer_access_token } });
}

export async function getReservations(customer_access_token, institution_name, user_id) {
  return axios.get(`${process.env.REACT_APP_BIDI_API_V2_URL}/v2/reservations?client_name=${institution_name}&frontuser_id=${user_id}&status=waiting`, { headers: { "x-access-token": customer_access_token } });
}

export async function deleteReservation(customer_access_token, reservation_id) {
  return axios.delete(`${process.env.REACT_APP_BIDI_API_V2_URL}/v2/reservations?reservation_id=${reservation_id}`, { headers: { "x-access-token": customer_access_token } });
}

export async function getReservationQueueSize(institution_name, catalog_item_id) {
  return axios.get(`${process.env.REACT_APP_BIDI_API_V2_URL}/v2/reservations/queuesize?client_name=${institution_name}&catalog_item_id=${catalog_item_id}`);
}

export async function createReservation(customer_access_token, institution_name, catalog_item_id, user_id) {
  const data = {
    client_name: institution_name,
    catalog_item_id,
    frontuser_id: user_id
  };
  return axios.post(`${process.env.REACT_APP_BIDI_API_V2_URL}/v2/reservations`, data, { headers: { "x-access-token": customer_access_token } });
}

export async function getCarrousels(institution_name) {
  const response = await axios.get(`${process.env.REACT_APP_BIDI_API_V2_URL}/v1/collections?add_book_metadata=true&client=${institution_name}&slice=12`)
    .then(response => {
      return response.data.collections.map(function (c) {
        c.link = "/collection/" + c.id + "?type=carrousel";
        return c;
      });
    }).then(collections => {
      if (collections.length !== 0) return collections;
      return getBooks(institution_name, 0, 18).then(responseBooks => {
        return [{
          'title': 'Recomendados',
          'link': '/catalogo',
          'items': responseBooks.data.books.map((book) => { return { 'book': book, 'external_id': book.id }; })
        }]
      });
    });

  return response;
}

export async function getCollection(institution_name, type, id) {
  const response = await axios.get(`${process.env.REACT_APP_BIDI_API_V2_URL}/v1/collections?add_book_metadata=true&type=${type}&client=${institution_name}&id=${id}`);
  return response.data;
}

export async function getFavourites(bidi_access_token, user_id) {
  return axios.get(`${process.env.REACT_APP_BIDI_API_V2_URL}/v2/user/collections?frontuser_id=${user_id}&type=favourite`, { headers: { "x-access-token": bidi_access_token } });
}
export async function addFavourite(bidi_access_token, user_id, catalog_item_id) {
  return axios.post(`${process.env.REACT_APP_BIDI_API_V2_URL}/v2/user/collections`, { frontuser_id: user_id, catalog_item_id, type: "favourite" }, { headers: { "x-access-token": bidi_access_token } });
}

export async function deleteFavourite(bidi_access_token, user_id, user_collection_id) {
  return axios.delete(`${process.env.REACT_APP_BIDI_API_V2_URL}/v2/user/collections`, { data: { frontuser_id: user_id, user_collection_id }, headers: { "x-access-token": bidi_access_token } });
}

export async function updateUserProfile(bidi_access_token, data) {
  return await axios.post(`${process.env.REACT_APP_BIDI_API_V2_URL}/v2/profile`, data, { headers: { "x-access-token": bidi_access_token } });
}

export async function getReaderUrl(download_url) {
  return await axios.get(`${download_url}/streaming:enabled`);
}

export async function getAudiobookData(download_url) {
  return await axios.get(`${download_url}`);
}

export async function getWebContentData(download_url) {
  /* Response example:
  {
    "delivery_id": "481522",
    "type": "link",
    "url": "http://www.medicapanamericana.com/VisorEbookV2/Ebook/9789500605540",
    "tag": [
      "panamericana",
      "hardlinked"
    ]
  }
  */
  return await axios.get(`${download_url}`);
}

export async function getAudiobookStatus(customer_access_token, external_id) {
  return await axios.get(`${process.env.REACT_APP_BIDI_API_V2_URL}/services/cognitosync?dataset_name=BookStatus`, { headers: { "x-access-token": customer_access_token } }).then(response => {
    const dataset = response.data.find(dataset => dataset.Key === String(external_id));
    if (!dataset) return;

    const data = JSON.parse(dataset.Value);
    if (!data) return;

    return {
      chapter: data.chapter,
      position: data.position
    }
  });
}

export async function syncAudiobookStatus(customer_access_token, external_id, delivery_id, chapter, total_chapters, position, final_position) {
  const data = {
    dataset_name: "BookStatus",
    dataset_key: external_id,
    dataset_value: JSON.stringify({ delivery_id, chapter, position, total_chapters, final_position })
  };

  return await axios.post(`${process.env.REACT_APP_BIDI_API_V2_URL}/services/cognitosync`, data, { headers: { "x-access-token": customer_access_token } });
}

export async function createSuggestion(institution_name, user_id, author_name, title_book, message, type) {
  const data = { institution_name, user_id, author_name, title_book, message, type };

  return await axios.post(`${process.env.REACT_APP_BIDI_API_V2_URL}/v2/suggestions`, data);
}

